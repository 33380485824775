<template>
  <vx-card title="Stock Opname">
    <vs-tabs :value="active">
      <vs-tab label="Complete">
        <div class="tab-text">
          <complete-component @tab="setTab"></complete-component>
        </div>
      </vs-tab>
      <vs-tab label="Ignore">
        <div class="tab-text">
          <ignore-component></ignore-component>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import CompleteComponent from "./complete";
import IgnoreComponent from "./ignore";
export default {
  components: {
    CompleteComponent,
    IgnoreComponent,
  },
  data() {
    return {
      active: 0,
    };
  },
  mounted() {},
  methods: {
    setTab(val) {
      switch (val) {
        case "Complete":
          this.active = 1;
          break;
        case "Ignore":
          this.active = 2;
          break;
        default:
          this.active = 0;
          break;
      }
    }
  }
};
</script>