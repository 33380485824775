<template>
  <div class>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-3/3 w-full">
        <span>{{ selected.title }}</span>
      </div>
      <div class="vx-col sm:w-3/3 w-full">{{ selected.storage_area }}</div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records">
          <vs-td style="align-item-left">{{tr.item_name}}</vs-td>
          <vs-td style="align-item-center">{{ tr.unit }}</vs-td>
          <vs-td style="align-item-center">{{ tr.quantity }}</vs-td>
          <vs-td style="align-item-center">{{ tr.batch }}</vs-td>
          <vs-td style="align-item-center">{{ tr.expired_date }}</vs-td>
          <vs-td style="align-item-center">{{ tr.quantity_real }}</vs-td>
          <vs-td style="align-item-center">{{ tr.delta }}</vs-td>
          <vs-td style="align-item-center">{{ tr.cogs }}</vs-td>
          
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  components: {},
  props: {
    dataId:Number,
    selected: {},
  },
  // created() {
  // },
  // mounted() {
  // },
  watch: {
    dataId() {
      this.reloadData(this.params);
      // console.log('The dataId has changed!')
    },
  },
  data: () => ({
    params: {
      search: "",
      length: 10,
      page: 1,
      order: "item_name",
      sort: "desc",
      status: 'complete'
    },
    header: [
      {
        text: "SKU",
        value: "item_name",
      },
      {
        text: "Handling Unit",
        value: "unit",
      },
      {
        text: "Qty",
        value: "quantity",
      },
      {
        text: "Batch",
        value: "batch",
      },
      {
        text: "Expired Date",
        value: "expired_date",
      },
      {
        text: "Qty Real",
        value: "quantity_real",
      },
      {
        text: "Delta",
        value: "delta",
      },
      {
        text: "Value",
        value: "cogs",
      },
    ],
    responseData: {},
  }),
  methods: {
    dateFormat(date){
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/report/stock-opname-detail/" + this.dataId, {
          params: {
            search: this.params.search,
            length: this.params.length,
            page: this.params.page,
            order: this.params.order,
            sort: this.params.sort,
            status: 'complete',

          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.responseData = resp.data;
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
.margin-btm {
  margin-bottom: 1rem !important;
}
.vs-input--input.normal {
  text-align: end;
}
</style>