<template>
    <div>
        <div class="vx-row mb-12">
          <div
            v-bind:class="[
              detail ? detailShow + ' vertical-divider' : '',
              detailHide,
            ]"
          >
            <data-table
              :responseData="responseData"
              :propsParams="params"
              :header="header"
              @reloadDataFromChild="reloadData"
            >
              <template slot="tbody">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  
                >

                  <vs-td>
                    <vs-button
                      v-on:click="clickTr(tr)"
                      color="primary"
                      type="gradient"
                      icon-pack="feather"
                      icon="icon-search"
                    ></vs-button>
                  </vs-td>
                  <vs-td>
                    {{ tr.warehouse }}
                  </vs-td>
                  <vs-td>
                    {{ tr.title }}
                  </vs-td>
                  <vs-td>
                    {{ tr.storage_area }}
                  </vs-td>
                  <vs-td>
                    {{ tr.date }}
                  </vs-td>
                </vs-tr>
              </template>
            </data-table>
          </div>
          <transition name="detail-fade">
            <div
              v-if="detail"
              v-bind:class="[
                detail ? ' nonfixed vx-col md:w-1/1 w-full mb-base' : '',
                detailHide,
              ]"
            >
              <div>
                <vs-col
                  style="padding-bottom: 8px"
                  vs-offset="8"
                  vs-type="flex"
                  vs-justify="rigth"
                  vs-align="rigth"
                  vs-w="4"
                >
                  <vs-button
                    size="small"
                    v-on:click="closeDetail"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                    >Close</vs-button
                  >
                </vs-col>

                <form-payment
                  :invoice="selectedData"
                  @closeDetail="closeDetail"
                ></form-payment>
                <detail :data-id="dataId" :selected="selectedData"></detail>
              </div>
            </div>
          </transition>
        </div>
      </div>
</template>


<script>
import detail from "./detail.vue";

    export default {
        components: {
          detail,
        },
        data() {
            return {
                params: {
                  search: "",
                  length: 10,
                  page: 1,
                  order: "date",
                  sort: "desc",
                  territoryCode: "",
                  status: "complete",
                },
                header: [
                  {
                    text: "Action",
                    value: "no",
                  },
                  {
                    text: "Warehouse",
                    value: "warehouse",
                  },
                  {
                    text: "Title",
                    value: "title",
                  },
                  {
                    text: "Storage Area",
                    value: "storage_area",
                  },
                  {
                    text: "Date",
                    value: "date",
                  },
                ],
                responseData: {},

                detailShow: "vx-col display-none w-full mb-base",
                detailHide: "vx-col md:w-1/1 w-full",
                detail: false,
                selectedData: {},
            };
        },
        methods: {
            clickTr(val) {
              this.closeDetail();
              setTimeout(function () {}, 3000);
              this.dataId = val.id;
              this.selectedData = val;
              this.detail = true;
            },
            closeDetail() {
              this.detail = false;
            },
            reloadData(params) {
              
              this.params = params;
              this.$vs.loading();
              console.log(this.params.status);
              this.$http
                .get("api/v1/report/stock-opname", {
                  params: {
                    search: this.params.search,
                    length: this.params.length,
                    page: this.params.page,
                    order: this.params.order,
                    sort: this.params.sort,
                    territoryCode: this.$userLogin.territory_code,
                    status: 'complete'
                  },
                })
                .then((resp) => {
                  if (resp.code == 500) {
                    this.$vs.loading.close();
                  } else if (resp.code == 200) {
                    this.$vs.loading.close();
                    this.data = resp.data.records;
                    this.responseData = resp.data;
                  } else {
                    this.$vs.loading.close();
                  }
                });
            },
        },
        mounted() {
            // this.reloadData(this.params);
        },
        watch: {

        },
        computed: {
            
        },
    };
</script>


<style scoped>
    .nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 50%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.red {
  background: red;
  background-color: red;
}
.yellow {
  background: yellow;
  background-color: yellow;
}
.display-none {
  display: none;
}
</style>